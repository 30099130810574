import { useState } from "react";
import "./DonationModal.scss";
import DonationSection from "./DonationSection";
import PersonalDetails from "./PersonalDetails";
import OtpVerification from "./OtpVerification";
import Confirmation from "./Confirmation";
import { t } from "i18next";
import TitleBar from "../TitleBar/TitleBar";
import { useNavigate } from "react-router-dom";

export function DonationModal({ onClose }) {
  const navigate = useNavigate()
  const [step, setStep] = useState(1);

  const [data, setData] = useState({
    amount: 0,
    reason_donation: "",
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    type: [],
  });
  const [err, setErr] = useState({});
  const [emailOtp, setEmailOtp] = useState("");
  const [endEmailTime, setEndEmailTime] = useState(Date.now() + 30000);
  const [isEmailCompleted, setIsEmailCompleted] = useState(true);
  const [phoneOtp, setPhoneOtp] = useState("");
  const [endPhoneTime, setEndPhoneTime] = useState(Date.now() + 30000);
  const [isPhoneCompleted, setIsPhoneCompleted] = useState(true);

  const onChangeHandler = (key, value) => {
    if (key == 'amount') {
      if (value > 100000) {
        value = 100000;
      }
    }
    setData({
      ...data,
      [key]: value,
    });
    setErr({
      ...err,
      [key]: "",
    });
  };

  const onTypeChangeHandler = (key, isChecked) => {
    setData({
      ...data,
      type: isChecked
        ? [...data.type, key]
        : data.type.filter((item) => item !== key),
    });
  };

  const emailOtpChangeHandler = (e) => {
    if (e.length === 4) {
    }
  };

  const emailOtpClear = () => {
    setEndEmailTime(Date.now() + 30000);
    setIsEmailCompleted(false);
    setEmailOtp("");
  };

  const phoneOtpClear = () => {
    setEndPhoneTime(Date.now() + 30000);
    setIsPhoneCompleted(false);
    setPhoneOtp("");
  };

  const handleCountryCode = (selectedOption) => {
    setErr((prev) => ({
      ...prev,
      country_code: "",
    }));

    setData((prev) => ({
      ...prev,
      country_code: selectedOption?.value || "",
    }));
  };

  return (
    (
      <div className="modal-container complaints__modal">
        <div className="modal-content">
          <button className="modal-close" onClick={onClose}>
            <img src="/assets/img/close-circle-svgrepo-com.svg" alt="" />
          </button>
          <TitleBar
            title={t('donate')}
            noBtn
            pt0
            onClose={() => {
              onClose();
              navigate("/"); 
            }}   
            breadcrumbs={[
              { name: t("home"), path: "/home" },
              { name: t("donate"), path: "" }
            ]}
          />



          <div className="modal-progress">
            <div
              className={`modal-progress-step`}
            >
              <div className={`modal-progress-step-icon ${step === 1 || step === 2 || step === 3 || step === 4 ? "active" : ""}`}>
                <img src="/assets/img/Group 13304.svg" alt="" />
              </div>
              <div className="modal-progress-step-label">{t("donation")}</div>
              <div className="modal-progress-step-sublabel">
                {t("select donation amount")}
              </div>
            </div>

            <div
              className={`modal-progress-step`}
            >
              <div className={`modal-progress-step-icon ${step === 2 || step === 3 || step === 4 ? "active" : ""}`}>
                <img src="/assets/img/id-card-svgrepo-com.svg" alt="" />
              </div>
              <div className="modal-progress-step-label">{t("personal details")}</div>
              <div className="modal-progress-step-sublabel">
                {t("fill your details")}
              </div>
            </div>

            <div
              className={`modal-progress-step`}
            >
              <div className={`modal-progress-step-icon ${step === 3 || step === 4 ? "active" : ""}`}>
                <img src="/assets/img/password-svgrepo-com.svg" alt="" />
              </div>
              <div className="modal-progress-step-label">{t("otp verification")}</div>
              <div className="modal-progress-step-sublabel">
                {t("verification of contact details")}
              </div>
            </div>

            <div className="modal-progress-step">
              <div className={`modal-progress-step-icon ${step === 4 ? "active" : ""}`}>
                <img src="/assets/img/succes-tick.svg" alt="" />
              </div>
              <div className="modal-progress-step-label">{t("payment link")}</div>
            </div>
          </div>

          <div className="divider"></div>

          {step === 1 && (
            <DonationSection
              step={step}
              setStep={setStep}
              onChangeHandler={onChangeHandler}
              data={data}
              err={err}
              setErr={setErr}
            />
          )}

          {step === 2 && (
            <PersonalDetails
              setStep={setStep}
              step={step}
              onChangeHandler={onChangeHandler}
              data={data}
              onTypeChangeHandler={onTypeChangeHandler}
              err={err}
              setData={setData}
              setErr={setErr}
              emailOtpClear={emailOtpClear}
              phoneOtpClear={phoneOtpClear}
              handleCountryCode = {handleCountryCode}
            />
          )}

          {step === 3 && (
            <OtpVerification
              setStep={setStep}
              emailOtp={emailOtp}
              setEmailOtp={setEmailOtp}
              endEmailTime={endEmailTime}
              setEndEmailTime={setEndEmailTime}
              isEmailCompleted={isEmailCompleted}
              setIsEmailCompleted={setIsEmailCompleted}
              emailOtpChangeHandler={emailOtpChangeHandler}
              phoneOtp={phoneOtp}
              setPhoneOtp={setPhoneOtp}
              endPhoneTime={endPhoneTime}
              setEndPhoneTime={setEndPhoneTime}
              isPhoneCompleted={isPhoneCompleted}
              setIsPhoneCompleted={setIsPhoneCompleted}
              data={data}
              setErr={setErr}
              err={err}
            />
          )}
          
          {step === 4 && <Confirmation onClose={onClose} type = {data?.type} />}
        </div>
      </div>
    )
  );
}

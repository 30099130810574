"use client";

import { useState, useEffect } from "react";
import { X, ArrowRight, ArrowLeft } from "lucide-react";
import "./PersonalDetails.scss";
import { sendOTP } from "../../redux/actionCreator";
import { useDispatch, useSelector } from "react-redux";
import { send_otp_loader, updateRedux } from "../../redux/commonReducer";
import { Col, Form, Row, Spinner } from "react-bootstrap";
import { t } from "i18next";
import Select from "react-select";
import { getCountries, getCountryCallingCode } from "libphonenumber-js";
import { useTranslation } from "react-i18next";

export default function PersonalDetails({
  step,
  setStep,
  onChangeHandler,
  data,
  onTypeChangeHandler,
  err,
  setErr,
  emailOtpClear,
  phoneOtpClear,
  handleCountryCode
}) {
  const dispatch = useDispatch();
  const [captchaValue, setCaptchaValue] = useState("");
  const [value, setValue] = useState("")

   const { t, i18n } = useTranslation();
  

  useEffect(() => {
    createCaptcha();
  }, []);

  function createCaptcha() {
    //clear the contents of captcha div first
    let code;
    document.getElementById("captcha").innerHTML = "";
    let charsArray =
      "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ@!#$%^&*";
    let lengthOtp = 6;
    let captcha = [];
    for (let i = 0; i < lengthOtp; i++) {
      //below code will not allow Repetition of Characters
      var index = Math.floor(Math.random() * charsArray?.length + 1); //get the next character from the array
      if (captcha.indexOf(charsArray[index]) == -1)
        captcha.push(charsArray[index]);
      else i--;
    }
    let canv = document.createElement("canvas");
    canv.id = "captcha";
    canv.width = 100;
    canv.height = 35;
    let ctx = canv.getContext("2d");
    ctx.font = "25px Georgia";
    ctx.strokeText(captcha.join(""), 0, 30);

    code = captcha.join("");
    document.getElementById("captcha").appendChild(canv);
    setCaptchaValue(code);
  }

  const submitLoading = useSelector(send_otp_loader)

  const handleBack = () => {
    setStep(1);
  };

  const countryCode = getCountries().map((code) => ({
    value: `+${getCountryCallingCode(code)}`,
    label: `+${getCountryCallingCode(code)}`,
  }));

  const validate = () => {
    let temp = {};
    if (!data?.first_name?.trim()?.length) {
      temp = {
        ...temp,
        first_name: t("first name is required"),
      };
    }
    if (!data?.last_name?.trim()?.length) {
      temp = {
        ...temp,
        last_name: t("last name is required"),
      };
    }
    if (!data?.country_code) {
      temp = {
        ...temp,
        country_code: t("code is required"),
      };
    }
    if (!value || value.trim() === "") {
      temp = {
        ...temp,
        captcha: t("Captcha is required"),
      };

    } else if (value !== captchaValue) {
      temp = {
        ...temp,
        captcha: t("Invalid captcha"),
      };

    }
    if (!data?.email?.trim()?.length) {
      temp = {
        ...temp,
        email: t("Email is required"),
      };
    } else {
      let regex =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,3}))$/;
      if (!regex.test(data?.email.trim())) {
        temp = {
          ...temp,
          email: t("Invalid email address"),
        };
      }
    }
    if (!data?.phone?.trim()?.length) {
      temp = {
        ...temp,
        phone: t("Phone Number is required"),
      };
    } else if (!/^\d{5,14}$/.test(data?.phone?.trim())) {
      temp = {
        ...temp,
        phone: t("Enter a valid phone number (5-14 digits)"),
      };
    }

    if (data?.type?.length == 0) {
      temp = {
        ...temp,
        type: t("mode is required"),
      };
    }
    if (Object.values(temp).length != 0) {
      setErr(temp);
      return false;
    } else {
      setErr({});
      return true;
    }
  };

  const submitHandler = () => {
    if (validate()) {
      submitDataHandler(() => {
        createCaptcha();
        setStep(3);
        emailOtpClear();
        phoneOtpClear();
      });
    }
  };

  const submitDataHandler = (cb) => {
    let raw = {
      first_name: data?.first_name,
      last_name: data?.last_name,
      phone: data?.phone,
      country_code: data?.country_code,
      email: data?.email,
      type: data?.type,
    };
    dispatch(
      sendOTP(raw, () => {
        cb();
      })
    );
  };

  return (
    <>

      <Row>
        <Col md={6} >
          <div className="form-group">
            <input
              type="text"
              name="first_name"
              placeholder={`${t("your first name")} *`}
              value={data?.first_name}
              onChange={(e) => onChangeHandler("first_name", e.target.value)}
              required
              className="form-control "
            />
            <p className="error">{err?.first_name}</p>
          </div>

        </Col>
        <Col md={6}>
          <div className="form-group" style={{ marginBottom: "0px" }}>
            <input
              type="text"
              name="last_name"
              placeholder={`${t("your last name")} *`}
              value={data?.last_name}
              onChange={(e) => onChangeHandler("last_name", e.target.value)}
              className="form-control"

            />
          </div>
          <p className="error">{err?.last_name}</p>
        </Col>

        <Col md={6}>
          <div className="form-group">
            <input
              type="email"
              name="email"
              placeholder={`${t("email")} *`}
              value={data?.email}
              onChange={(e) => onChangeHandler("email", e.target.value)}
              required
              className="form-control"
            />
            <p className="error">{err?.email}</p>
          </div>
        </Col>

        <Col md={6}>
          <div className="form-group mb-3">
            <div className="d-flex gap-2">
              <Select
                options={countryCode}
                value={countryCode.find(option => option.value === data?.country_code) || ""}
                className="country_code"
                onChange={handleCountryCode
                }
                placeholder={`${t("Code")}*`}
                styles={{
                  control: (provided) => ({
                    ...provided,
                    minHeight: "33px",
                  }),
                  valueContainer: (provided) => ({
                    ...provided,
                    height: "33px",
                    padding: "0px 6px",
                  }),
                  indicatorsContainer: (provided) => ({
                    ...provided,
                    height: "33px",
                  }),
                }}
              />
              <input
                type="number"
                name="phone"
                value={data?.phone || ""}
                className="form-control "
                placeholder={`${t("phone number")} *`}
                onChange={(e) => onChangeHandler("phone", e.target.value)}
              />
            </div>
            <div className="d-flex gap-3">
              {err?.country_code && <p className="error">{err.country_code}</p>}
              {err?.phone && <p className="error">{err.phone}</p>}
            </div>

          </div>
        </Col>

      </Row>
      <div className="modal-form-contact">
        <span className="contact-label" style={{ color: "black" }}>
          {t("mode of contact?")}
        </span>
        <div className="checkbox-group">
          <label
            className="checkbox-label"
            for="email"
            style={{ color: "black" }}
          >
            {t("email")}
            <Form.Check
              type={"checkbox"}
              id={`default-checkbox-email`}
              name="contact-email"
              label={``}
              className="type__checkbox"
              checked={data?.type.includes("email")}
              onChange={(e) => {
                onTypeChangeHandler("email", e.target.checked);
                setErr({ ...err, type: "" });
              }}
            />

          </label>
          <label
            className="checkbox-label"
            for="phone"
            style={{ color: "black" }}
          >
            {t("phone")}
            <Form.Check 
              type={"checkbox"}
              id={`default-checkbox`}
              name="contact-phone"
              className="type__checkbox"
              label={``}
              checked={data?.type.includes("phone")}
              onChange={(e) => {
                onTypeChangeHandler("phone", e.target.checked)
                setErr({ ...err, type: "" });
              }}
            />
          </label>
        </div> <br />
        {err?.type && (
          <p className="error">{err?.type}</p>
        )}

      </div>
      <Row>
        <Col md={6} className="form-group mb-2">
        <div className={"captcha_alignnment"+(i18n?.language === "en"?' lang-en':' lang-ar')}>
            <div id="captcha"></div>
            <input
              type="text"
              placeholder={t("enter captcha")}
              id="cpatchaTextBox"
              className="form-control"
              value={value}
              onChange={(e) => {
                setValue(e.target.value);
                setErr((prevErrors) => ({
                  ...prevErrors,
                  captcha: ""
                }));
              }}
              autoComplete="off"
            />

            <div className="text-right captcha_refresh">
              <div className="refresh-capcha" onClick={() => {
                createCaptcha();
                setValue("");
              }}>
                <img src="assets/img/icons/refresh.svg" alt="refresh captcha" />
              </div>
            </div>
          </div>

          <p className="error">{err?.captcha}</p>
        </Col>
      </Row>

      <div className="modal-navigation">
        <button className="back" onClick={handleBack}>
          <img src="/assets/img/Group 164.svg" alt="good" className={`mx-2 ${i18n?.language !== "en" ? "arrow_image" : ""}`}  />
          {t("back")}
        </button>
        <button className="next" onClick={submitHandler}
          disabled={submitLoading}
        >
          {t("next")}
          {submitLoading ?
            <div style={{ width: 50 }}>
              <Spinner animation="border" size="sm" />
            </div>
            : <img src="/assets/img/Group 164.svg" alt="good" className={`mx-2 ${i18n?.language !== "en" ? "arrow_image" : ""}`}  />}
        </button>
      </div>
    </>
  );
}

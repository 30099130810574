import React, { useEffect, useState } from "react";

import { Col, Container, Row } from "react-bootstrap";
import SwiperComponent from "../../commonComponents/SwiperComponent/SwiperComponent";
import Announcement from "./Announcement";
import kidImage from "../../assets/images/kid_high.png";
import mainImage from "../../assets/images/main_low.png";
import rightArrow from "../../assets/images/right_arrow_yellow.svg";
import "./Home.scss";
import IntroSection from "./IntroSection";
import AboutSection from "./AboutSection";
import RoundedButton from "../../commonComponents/RoundedButton/RoundedButton";
import { WhatWeDoSection } from "./WhatWeDoSection";
import LatestNewsSection from "./LatestNewsSection";
import LocalEvents from "./LocalEvents";
import InternationalEvents from "./InternationalEvents";
import Libraries from "./Libraries";
import "../PageResponsive.scss";
import "../rtl.scss";
import {
  getAllHomeData,
  getAnnouncements,
  getFeaturedEvents,
  getFeaturedNews,
  getInstaFeed,
} from "../../redux/actionCreator";
import { useDispatch, useSelector } from "react-redux";
import {
  announcements_Lists,
  eventLists,
  featuredEventLists,
  featuredNews,
  getHome_announcements,
  getHome_banner,
  getHome_details,
  home_loader,
  instaFeed_lists,
} from "../../redux/commonReducer";
import { SwiperSlide } from "swiper/react";
import { API_BASE_URL, API_IMAGE_URL } from "../../constants/configuration";
import { Link, useNavigate } from "react-router-dom";
import i18next, { t } from "i18next";
import i18n from "../../i18n";
import Title from "../../commonComponents/Title/Title";
import MetaHead from "../../components/MetaTitle/MetaHead";

const Home = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [lang, setLang] = useState("");

  // USE SELECTORS
  const bannerData = useSelector(getHome_banner);
  const announcements = useSelector(featuredEventLists);
  const bannerDetails = useSelector(getHome_details);
  const loader = useSelector(home_loader);
  const eventItems = useSelector(eventLists);
  const featured_News = useSelector(featuredNews);
  const InstaFeed = useSelector(instaFeed_lists);

  // LANGUAGE
  const langSwitch = i18n?.language === "en";

  useEffect(() => {
    i18n.on("languageChanged", (language) => {
      setLang(language);
    });
  }, [lang, i18n]);

  useEffect(() => {
    dispatch(getInstaFeed());
    dispatch(getAllHomeData());
    dispatch(getFeaturedNews());

    let formData = new FormData();
    formData.append("event_type", "Local");
    dispatch(getFeaturedEvents(formData));
  }, []);

  const SwiperSlider = (
    <>
      {bannerData?.length > 0 ? (
        <>
          {bannerData?.map((ele, i) => {
            return (
              <SwiperSlide key={i}>
                <section
                  className="humanRights__banner"
                  style={{
                    backgroundImage: ele?.image
                      ? `url('${API_IMAGE_URL}${ele?.file_path}${ele?.image}')`
                      : `url('./assets/img/dummy.png'`,
                  }}
                >
                  <div className="overlay"></div>
                  <div className="banner__content animated animatedFadeInUp fadeInUp">
                    <h1>{langSwitch ? ele?.title : ele?.title_ar}</h1>
                    <p>{langSwitch ? ele?.desc : ele?.desc_ar}</p>
                    <Link to={ele?.view_more_link} target="_blank">
                      <RoundedButton
                        varient={"primary"}
                        title={t("tell me more")}
                        style={{ minWidth: "190px" }}
                      />
                    </Link>
                  </div>
                </section>
              </SwiperSlide>
            );
          })}
        </>
      ) : (
        <section
          className="humanRights__banner"
          style={{
            backgroundPosition: "center",
            backgroundImage: `url('./assets/img/dummy.png'`,
          }}
        >
          <div className="overlay"></div>
        </section>
      )}
    </>
  );
  return (
    <Container className="home__page">
      {/* <MetaHead title={'test'} keyword={'test'} description={'test'}/> */}
      {loader ? (
        <div className="banner_loader"></div>
      ) : (
        <SwiperComponent
          fade={"fade"}
          pagination
          data={[SwiperSlider]}
          className="home__banner"
          autoplay={{
            delay: 5000,
            disableOnInteraction: true,
          }}
          // dataArray={[1, 1, 1, 1, 1, 1, 1, 1]}
        />
      )}
      
      {announcements?.length > 0 ? (
        <Announcement data={announcements} langSwitch={langSwitch} />
      ) : (
        <div className="mb-5"></div>
      )}
      {bannerDetails?.sub_title?.trim() != "" &&
      bannerDetails?.sub_desc?.trim() != "" ? (
        <IntroSection data={bannerDetails} langSwitch={langSwitch} />
      ) : (
        ""
      )}

      <AboutSection langSwitch={langSwitch} />
      <WhatWeDoSection data={bannerDetails} />
      {featured_News?.length > 0 ? <LatestNewsSection /> : ""}

      <Row>
        <Col md={12} lg={6}>
          <LocalEvents />
        </Col>
        <Col md={12} lg={6}>
          <InternationalEvents />
        </Col>
      </Row>
      <section className="humanRights__library">
        <Row>
          <div className="col-md-12">
            <Libraries />
          </div>
        </Row>
      </section>
      {/* <section className="instagram__feed">
        <div className="container">
          <Title title={t("instagram feed")} />
          <div className="row">
            {
              InstaFeed?.map((ele, i) => {
                return (
                  <div className="col-md-3" key={i}>
                    <a href={ele?.permalink} target="_blank">
                      <div className="feedGrid">
                        <img src={ele?.thumbnail_url ?? ele.media_url} className="w-100 posts" alt="" />
                        <div className="icon">
                          <img src="./assets/img/icons/insta-feed.svg" alt="" />
                        </div>
                      </div>
                    </a>
                  </div>
                )
              })
            }
          </div>
        </div>
      </section> */}
    </Container>
  );
};

export default Home;

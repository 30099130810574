import { ArrowRight } from "lucide-react";
import React from "react";
import { t } from "i18next";
import { useTranslation } from "react-i18next";

const DonationSection = ({
  setStep,
  step,
  onChangeHandler,
  data,
  err,
  setErr,

}) => {
  const { t, i18n } = useTranslation();
  const langSwitch = i18n?.language === 'en'

  if (step !== 1) return null;

  const validate = () => {
    let temp = {};
    if (Number(data?.amount) <= 0) {
      temp = {
        ...temp,
        amount: t("please enter donation amount"),
      };
    }
    if (!data?.reason_donation?.trim()?.length) {
      temp = {
        ...temp,
        reason_donation: t("please enter reason"),
      };
    }
    if (Object.values(temp).length != 0) {
      setErr(temp);
      return false;
    } else {
      setErr({});
      return true;
    }
  };

  const nextHandler = () => {
    if (validate()) {
      setStep(2);
    }
  };

  return (
    <div>
      <div className="modal-amount padding_section">
        <h5 className="modal-amount-title">{t("how much you wish to donate?")}</h5>
        <div className="modal-amount-slider">
          <div className="slider-container">
            <p
              className="amount-value"
              style={{
                ...(langSwitch
                  ? { left: `calc(${(data?.amount / 100000) * 100}% - 12px)` }
                  : { right: `calc(${(data?.amount / 100000) * 100}% - 12px)` }
                ),
                whiteSpace: 'nowrap'
              }}
              
            >
              {data?.amount} AED
            </p>
            <input
              type="range"
              min="0"
              max="100000"
              value={data?.amount}
              onChange={(e) => onChangeHandler("amount", Number(e.target.value))}
              step={10}
              style={{
                backgroundSize: `${(data?.amount / 100000) * 100}% 100%`
              }}
            />
          </div>
        </div>

        <div className="modal-amount-input">
          <input
            type="number"
            value={data?.amount || ""}
            onChange={(e) => onChangeHandler("amount", Number(e.target.value))}
            placeholder={t("enter manually")}
            min={0}
            max={100000}
            className="form-control"
          />
          <span>AED</span>
        </div>
        <p className="error">{err?.amount}</p>
      </div>
      <div className="divider"></div>
      <div className="modal-reason padding_section">
        <label className="modal-reason-label">{t("reason for donation")}</label>
        <textarea
          value={data?.reason_donation}
          onChange={(e) => onChangeHandler("reason_donation", e.target.value)}
          placeholder={t("type here")}
          className="form-control"
        />
        <p className="error">{err?.reason_donation}</p>
      </div>
      <div className="modal-next">
        <button onClick={() => nextHandler()}>
          {t("next")}
          <img src="/assets/img/Group 164.svg" alt="good" className={`mx-2 ${i18n?.language !== "en" ? "arrow_image" : ""}`}  />
        </button>
      </div>
    </div>
  );
};

export default DonationSection;

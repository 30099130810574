import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import TitleBar from "../components/TitleBar/TitleBar";
import { useDispatch, useSelector } from "react-redux";
import { contactEnquiry } from "../redux/actionCreator";
import { toast } from "react-toastify";
import i18n from "../i18n";
import { contact_details, contact_loader } from "../redux/commonReducer";
import { t } from "i18next";
import { useRef } from "react";

function Contact() {

  const dispatch = useDispatch()

  // SELECTORS
  const contactData = useSelector(contact_details)

  const [disabled, setDisabled] = useState(false)
  const ref = useRef()
  const fileInputRef = useRef(null)
  // LANGUAGE
  const [lang, setLang] = useState("");
  const langSwitch = i18n?.language === "en";
  useEffect(() => {
    i18n.on("languageChanged", (language) => {
      setLang(language);
    });
  }, [lang, i18n]);

  const [errors, setErrors] = useState({});
  const [captchaValue, setCaptchaValue] = useState("");
  const [value, setValue] = useState("")
  const [data, setData] = useState({
    name: '',
    email: '',
    phone: '',
    message: '',
    image:''
  });

  useEffect(() => {
    setErrors({})
  }, [langSwitch])

  useEffect(() => {
    createCaptcha();
    console.log('useeeeeeeeeeeee')
  }, []);

  const handleInputChange = (e) => {
    const { name, type, value, files } = e.target;

    if (name === "image") {
        if (files?.length) {
            if (files[0].type !== "application/pdf") {
                setErrors((prevErrors) => ({ ...prevErrors, image: "Only PDF files are allowed!" }));
                return;
            }
            setData((prevData) => ({
                ...prevData,
                [name]: files[0],
            }));
        } else {
            setData((prevData) => ({
                ...prevData,
                [name]: prevData?.image, 
            }));
        }
    } else {
        setData((prevData) => ({
            ...prevData,
            [name]: value.trim(),
        }));
    }

    setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: "",
    }));
};



  const validateForm = () => {
    const newErrors = {};
    if (!data?.name?.trim()) {
      newErrors.name = t("name is required");
    } else if (!/^[A-Za-z\s]+$/.test(data.name)) {
      newErrors.name = t("Invalid name format, allow only alphabets");
    }

    if (!data?.email?.trim()) {
      newErrors.email = t("Email is required");
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(data.email)) {
      newErrors.email = t("Invalid email address");
    }
    if (!data?.subject?.trim()) {
      newErrors.subject = t("subject is required");
    }

    if (!data?.phone?.trim()) {
      newErrors.phone = t("Phone Number is required");
    } else if (!/^\+?\d{5,14}$/.test(data.phone)) {
      newErrors.phone = t("Invalid phone number format");
    }

    if (!data?.message?.trim()) {
      newErrors.message = t("Message is required");
    }
    const allowedFormats = /\.pdf$/i; 
    if (data?.image && !allowedFormats.test(data.image.name) ) {
      newErrors.image = t('Invalid file format. Only PDF is supported'); 
  }

    if (!value || value.trim() === "") {
      newErrors.captcha = t("captcha is required");
    } else if (value !== captchaValue) {
      newErrors.captcha = t("Invalid captcha");
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };



  function createCaptcha() {
    //clear the contents of captcha div first
    let code;
    document.getElementById("captcha").innerHTML = "";
    let charsArray =
      "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ@!#$%^&*";
    let lengthOtp = 6;
    let captcha = [];
    for (let i = 0; i < lengthOtp; i++) {
      //below code will not allow Repetition of Characters
      var index = Math.floor(Math.random() * charsArray?.length + 1); //get the next character from the array
      if (captcha.indexOf(charsArray[index]) == -1)
        captcha.push(charsArray[index]);
      else i--;
    }
    let canv = document.createElement("canvas");
    canv.id = "captcha";
    canv.width = 100;
    canv.height = 50;
    let ctx = canv.getContext("2d");
    ctx.font = "25px Georgia";
    ctx.strokeText(captcha.join(""), 0, 30);

    code = captcha.join("");
    document.getElementById("captcha").appendChild(canv);
    setCaptchaValue(code);
  }




  const handleSubmit = (e) => {
    e.preventDefault();
    const isValid = validateForm();
     
    if (isValid) {
        setDisabled(true);
        console.log(data)
        let formData = new FormData();
        formData.append('name', data?.name);
        formData.append('email', data?.email);
        formData.append('phone', data?.phone);
        formData.append('message', data?.message);
        formData.append('subject', data?.subject); 
        formData.append('image', data?.image);


        dispatch(contactEnquiry(formData, (res) => {
            if (res) {
              toast.success(res.data.message, {
                position: "bottom-center",
                autoClose: 3000
              })
                createCaptcha();
                setValue("");
                setDisabled(false);
                setData({
                    name: '',
                    email: '',
                    phone: '',
                    message: '',
                    image: ''
                });
                e.target.reset();
            }
        }))
    }
};





  return (
    <section className="contact_us">
      <Container>
        <TitleBar
          title={t('contact us')}
          breadcrumbs={[
            { name: `${t('home')}`, path: "/" },
            { name: `${t('contact us')}`, path: "/contact" },
          ]}
          noBtn
        />

        <div className="contact__details">
          <div className="row align-items-center">
            <div className="col-md-6">
              <div className="address_grid" data-aos="fade-up" data-aos-duration="1000">
                <div className="icon">
                  <img src="/assets/img/icons/c-location.svg" alt="" />
                </div>
                <div className="content">
                  <h4>{langSwitch ? contactData?.address_title : contactData?.address_title_ar}</h4>
                  <p>{langSwitch ? contactData?.address : contactData?.address_ar}</p>
                </div>
              </div>
              <div className="address_grid" data-aos="fade-up" data-aos-duration="1500">
                <div className="icon">
                  <img src="/assets/img/icons/c-mail.svg" alt="" />
                </div>
                <div className="content">
                  <h4>{t('email')}</h4>
                  <p><a href={`mailto:${contactData?.email}`}>{contactData?.email}</a></p>
                </div>
              </div>
              <div className="address_grid" data-aos="fade-up" data-aos-duration="2000">
                <div className="icon">
                  <img src="/assets/img/icons/c-phone.svg" alt="" />
                </div>
                <div className="content">
                  <h4>{t('phone')}</h4>
                  <p><a href={`tel:${contactData?.mobile}`}>{contactData?.telephone}</a></p>
                </div>
              </div>
            </div>

            <div className="col-md-6 text-right" data-aos="fade-up" data-aos-duration="1500">
              <form onSubmit={(e) => handleSubmit(e)}>
                <div className="forms">
                  <div className="form-group">
                    <input type="text" name="name" ref={ref} className="form-control" placeholder={t('name')} onChange={handleInputChange} autoComplete="off" />
                    <p className="error">{errors?.name}</p>
                  </div>
                  <div className="form-group">
                    <input type="text" name="email" ref={ref} className="form-control" placeholder={t('email')} onChange={handleInputChange} autoComplete="off" />
                    <p className="error">{errors?.email}</p>
                  </div>
                  <div className="form-group">
                    <input type="number" name="phone" ref={ref} className="form-control" placeholder={t('phone number')} onChange={handleInputChange} autoComplete="off" />
                    <p className="error">{errors?.phone}</p>
                  </div>
                  <div className="form-group">
                    <input type="text" name="subject" ref={ref} className="form-control" placeholder={t('subject')} onChange={handleInputChange} autoComplete="off" />
                    <p className="error">{errors?.subject}</p>
                  </div>
                  <div className="form-group ">
                    <textarea name="message" ref={ref} placeholder={t('message')} rows="4" className="form-control" onChange={handleInputChange} autoComplete="off"  ></textarea>
                    <p className="error">{errors?.message}</p>
                  </div>
                    <div className="form-group">
                    <div className="form-group upload_attachment" onClick={() => fileInputRef.current.click()}>
                      <input
                        name="image"
                        onChange={handleInputChange}
                        multiple={false}
                        ref={fileInputRef}
                        type="file"
                        accept=".pdf"
                        hidden
                      />
                      <div>
                        <img width={30} src={'./assets/img/upload-cloud.svg'} alt="" />
                        <label
                          className="mx-3 attachement_text"
                          onClick={() => {
                            const fileUrl = data?.image && URL.createObjectURL(data?.image);
                            data?.image && window.open(fileUrl, '_blank');
                          }}
                        >
                          {data?.image ? data?.image?.name : t('Upload Attachment')}

                        </label>
                      </div>
                      <p className="supporting_document_text">{t('Supporting Document')} PDF</p>
                     
                    </div>
                    <p className="error">{errors?.image}</p>
                    </div>
                  <div className="form-group mb-2">

                  <div className={"captcha_alignnment"+(i18n?.language === "en"?' lang-en':' lang-ar')}>
                      <div id="captcha"></div>
                      <input
                        type="text"
                        placeholder={t("enter captcha")}
                        id="cpatchaTextBox"
                        className="form-control"
                        value={value}
                        onChange={(e) => {
                          setValue(e.target.value);
                          setErrors((prev) => ({
                              ...prev,
                              captcha: ""
                          }));
                      }}
                      
                        autoComplete="off"
                      />
                      <div className="text-right captcha_refresh">
                        <div className="refresh_captcha" onClick={() => (createCaptcha(), setValue(""))}><img src="assets/img/icons/refresh.svg" /></div>
                      </div>
                    </div>
                    {errors?.captcha && (
                  
                    <p className="error">{errors?.captcha}</p>
                  )}
                  </div>

                  <button className="btn btn-outline-primary text-uppercase" >{t('submit now')}</button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className="map__container">
          <div className="row">
            <div
              id="map-container-google-2"
              className="z-depth-1-half map-container"
            >
              <iframe
                src={`${contactData?.google_map_link}`}
                frameborder="0"
                allowfullscreen
              ></iframe>
            </div>
          </div>
        </div>
      </Container>
    </section>
  );
}

export default Contact;

import React, { useEffect, useRef, useState } from "react";
import {
  Col,
  Container,
  Row,
} from "react-bootstrap";
import TitleBar from "../../components/TitleBar/TitleBar";
import { t } from "i18next";
import { useDispatch, useSelector } from "react-redux";
import { getBlogs } from "../../redux/actionCreator";
import { blog_data, blog_page_details } from "../../redux/commonReducer";
import { API_IMAGE_URL } from "../../constants/configuration";
import moment from "moment";
import i18n from "../../i18n";
import { useNavigate } from "react-router-dom";
import RoundedButton from "../../commonComponents/RoundedButton/RoundedButton";

const Blogs = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const langSwitch = i18n?.language === "en";

  const blogData = useSelector(blog_data);
  const pageDetails = useSelector(blog_page_details);

  const [data, setData] = useState({
    page: 0,
    perPage: 10,
  });

  const [lang, setLang] = useState("");

  useEffect(() => {
    i18n.on("languageChanged", (language) => {
      setLang(language);
    });
  }, [lang, i18n]);

  useEffect(() => {
    getSearchDataHandler(data);
  }, []);

  const getSearchDataHandler = (filter) => {
    const formData = new FormData();
    formData.append("page", filter.page);
    formData.append("perPage", filter.perPage);
    formData.append("home", false);
    dispatch(getBlogs(formData));
  };

  const handlePageChange = () => {
    let temp;
    temp = {
      page: data?.page,
      perPage: data?.perPage + 4,
    };
    setData({
      ...temp,
    });
    getSearchDataHandler(temp);
  };

  return (
    <section className="blogs">
      <Container>
        <TitleBar
          title={t("our blog")}
          breadcrumbs={[
            { name: `${t("home")}`, path: "/" },
            { name: `${t("blogs")}`, path: "/blogs" },
          ]}
          noBtn
        />
        <div className="w-100">
          <Row className="mb-3">
            {blogData?.slice(0, 2)?.map((ele, i) => {
              return (
                <Col
                  md={6}
                  sm={12}
                  onClick={() => navigate(`/blogs/blog-details/${ele?._id}`)}
                  style={{ "cursor": "pointer" }}
                >
                  <img
                    className="blog_image mb-4"

                    src={`${API_IMAGE_URL}${ele?.file_path}${ele?.image}`}
                  />
                  <h3 className="mb-4 " >

                    {
                      langSwitch
                        ? ele?.title?.length > 90
                          ? ele?.title?.slice(0, 90) + "......"
                          : ele?.title
                        : ele?.title_ar?.length > 90
                          ? ele?.title_ar?.slice(0, 90) + "......"
                          : ele?.title_ar
                    }

                  </h3>
                  <div className="blog__details mt-2">
                    <Row>
                      <Col md={6} className="d-flex align-items-center justify-content-start right__border">
                        <img src="/assets/img/event.svg" width={15} className="mx-2" />
                        {moment(ele?.published_date).format("MMMM D, YYYY")}
                      </Col>
                     
                      <Col md={6} className="d-flex align-items-center justify-content-start">
                        <img src="/assets/img/clock.svg" width={15} className="mx-2" />
                        {moment(ele?.published_date).format("h:mm A")}
                      </Col>
                    </Row>
                  </div>
                  <p
                    className="mt-2"
                    dangerouslySetInnerHTML={{
                      __html: langSwitch
                        ? ele?.content?.length > 300
                          ? ele?.content?.slice(0, 300) + "(......)"
                          : ele?.content
                        : ele?.content_ar?.length > 300
                          ? ele?.content_ar?.slice(0, 300) + "(......)"
                          : ele?.content_ar,
                    }}
                  ></p>
                </Col>
              );
            })}
          </Row>
          <Row className="mt-5">
            {blogData?.slice(2)?.map((ele, i) => {
              return (
                <Col
                  md={3}
                  sm={12}
                  onClick={() => navigate(`/blogs/blog-details/${ele?._id}`)}
                  style={{ "cursor": "pointer" }}
                >
                  <img
                    className="blog_detail_image"
                    width={"100%"}
                    src={`${API_IMAGE_URL}${ele?.file_path}${ele?.image}`}
                  />

                  <div className="blog__details mt-2 read__more__blogs">
                    <Row>
                      <Col md={6} className="d-flex align-items-center justify-content-start right__border">
                        <img src="/assets/img/event.svg" width={15} className="mx-2" />
                        {moment(ele?.published_date).format("MMMM D, YYYY")}
                      </Col>
                      <Col md={6} className="d-flex align-items-center justify-content-start">
                        <img src="/assets/img/event.svg" width={15} className="mx-2" />
                        {moment(ele?.published_date).format("h:mm A")}
                      </Col>
                    </Row>

                  </div>

                  <h5 className="mt-2">
                    {
                      langSwitch
                        ? ele?.title?.length > 60
                          ? ele?.title.slice(0, 60) + "......"
                          : ele?.title ?? ""
                        : ele?.title_ar?.length > 60
                          ? ele?.title_ar.slice(0, 60) + "......"
                          : ele?.title_ar ?? ""
                    }

                  </h5>

                  <p
                    className="mt-2"
                    dangerouslySetInnerHTML={{
                      __html: langSwitch
                        ? ele?.content?.length > 100
                          ? ele?.content?.slice(0, 100) + "(...)"
                          : ele?.content
                        : ele?.content_ar?.length > 100
                          ? ele?.content_ar?.slice(0, 100) + "(...)"
                          : ele?.content_ar,
                    }}
                  ></p>
                </Col>
              );
            })}
          </Row>
        </div>
        {pageDetails?.pages == 1 ? (
          <></>
        ) : (
          <div className="text-center mb-5 mt-4">
            <RoundedButton
              varient="secondary"
              style={{ minWidth: "190px" }}
              onClick={() => handlePageChange()}
              title={t("View more")}
            />
          </div>
        )}
      </Container>
    </section>
  );
};

export default Blogs;

import React, { useEffect, useRef, useState } from "react";
import Select from "react-select";
import { Col, Container, Form, InputGroup, Row, Spinner } from "react-bootstrap";
import TitleBar from "../components/TitleBar/TitleBar";
import { useDispatch, useSelector } from "react-redux";
import { contactVolunteerForm, getVolunteerDetails } from "../redux/actionCreator";
import { toast } from "react-toastify";
import i18n from "../i18n";
import { contact_details, contact_loader, volunteer_details, volunteerform_loading } from "../redux/commonReducer";
import { t } from "i18next";
import { Link } from "react-router-dom";
import icon from "../assets/images/right_arrow_yellow.svg";
import { API_IMAGE_URL } from "../constants/configuration";
import { getCountries, getCountryCallingCode } from "libphonenumber-js";

function VolunteerWithUs() {

    const ref = useRef()
    const fileInputRef = useRef();
    const dispatch = useDispatch()

    const loading = useSelector(volunteerform_loading)
    const details = useSelector(volunteer_details)
    const [errors, setErrors] = useState({});
    const [captchaValue, setCaptchaValue] = useState("");
    const [value, setValue] = useState("")
    const [data, setData] = useState({});
    const [lang, setLang] = useState("")
    const langSwitch = i18n?.language === "en";

    useEffect(() => {
        dispatch(getVolunteerDetails());
    }, []);

    const countryCode = getCountries().map((code) => ({
        value: `+${getCountryCallingCode(code)}`,
        label: `+${getCountryCallingCode(code)}`,
    }));

    useEffect(() => {
        i18n.on("languageChanged", (language) => {
            setLang(language);
        });
    }, [lang, i18n]);

    useEffect(() => {
        createCaptcha();
    }, []);

    function createCaptcha() {
        //clear the contents of captcha div first
        let code;
        document.getElementById("captcha").innerHTML = "";
        let charsArray =
            "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ@!#$%^&*";
        let lengthOtp = 6;
        let captcha = [];
        for (let i = 0; i < lengthOtp; i++) {
            //below code will not allow Repetition of Characters
            var index = Math.floor(Math.random() * charsArray?.length + 1); //get the next character from the array
            if (captcha.indexOf(charsArray[index]) == -1)
                captcha.push(charsArray[index]);
            else i--;
        }
        let canv = document.createElement("canvas");
        canv.id = "captcha";
        canv.width = 100;
        canv.height = 35;
        let ctx = canv.getContext("2d");
        ctx.font = "25px Georgia";
        ctx.strokeText(captcha.join(""), 0, 30);

        code = captcha.join("");
        document.getElementById("captcha").appendChild(canv);
        setCaptchaValue(code);
    }




    const handleInputChange = (e) => {
        const { name, type, value, files, checked } = e.target;
        setData((prevData) => ({
            ...prevData,
            [name]: name === "image"
                ? (files?.length ? files[0] : prevData.image)
                : (type === "checkbox" ? checked : value.trim()),
        }));

        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: "",
        }));
    };

    const handleCountryCode = (selectedOption) => {
        setErrors((prev) => ({
            ...prev,
            country_code: "",
        }));

        setData((prev) => ({
            ...prev,
            country_code: selectedOption?.value || "",
        }));
    };



    const validateFileFormat = (file) => {
        const allowedFormats = /\.(jpg|jpeg|png|pdf)$/i;
        return file && allowedFormats.test(file.name);
    };

    const validation = async () => {
        let isValid = true;
        let errors = {};

        if (!data?.name?.trim()) {
            errors.name = t('first name is required');
            isValid = false;
        }

        if (!data?.last_name?.trim()) {
            errors.last_name = t('last name is required');
            isValid = false;
        }

        if (!data?.image) {
            errors.image = t('cv is required');
            isValid = false;
        } else if (!validateFileFormat(data.image)) {
            errors.image = t('Invalid file format. Only JPG, PNG, and PDF are supported');
            isValid = false;
        }

        if (data.agree !== true) {
            errors.agree = t('You must agree to terms and conditions');
            isValid = false;
        }
        if (!data?.country_code?.trim()) {
            errors.country_code = t('code is required');
            isValid = false;
        }

        if (!data?.email?.trim()) {
            errors.email = t('Email is required');
            isValid = false;
        } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(data.email)) {
            errors.email = t('Invalid email address');
            isValid = false;
        }
        if (!value || value.trim() === "") {
            errors.captcha = t("captcha is required");
            isValid = false
        } else if (value !== captchaValue) {
            errors.captcha = t("Invalid captcha");
            isValid = false
        }

        if (!data?.phone?.trim()) {
            errors.phone = t('Phone Number is required');
            isValid = false;
        } else if (!/^\+?\d{9,13}$/.test(data.phone)) {
            errors.phone = t('Invalid phone number');
            isValid = false;
        }

        setErrors(errors);
        return isValid;
    };



    const handleSubmit = async () => {
        const isValidated = await validation()
        if (!isValidated) {
            return
        } else {
            let formData = new FormData();
            formData.append('name', data?.name);
            formData.append('last_name', data?.last_name);
            formData.append('image', data?.image);
            formData.append('type', 'volunteer');
            formData?.append('country_code', data?.country_code)
            formData.append('phone', data?.phone);
            formData.append('email', data?.email);

            dispatch(contactVolunteerForm(formData, (res) => {
                toast.success(res?.data?.message, {
                    position: "bottom-center",
                    autoClose: 3000
                })
                createCaptcha();
                setData({
                    name: '',
                    last_name: "",
                    email: '',
                    phone: '',
                    agree: '',
                    image: '',

                });
                setValue("")
            }))
        }
    }


    return (
        <section className="volunteer_with_us">
            <Container>
                <TitleBar
                    title={langSwitch ? details?.title : details?.title_ar}
                    breadcrumbs={[
                        { name: `${t('home')}`, path: "/" },
                        { name: `${t('volunteer with us')}`, path: "/volunteer_with_us" },
                    ]}
                    noBtn
                />
                <Row className='align-items-center home__about mb-4'>
                    <Col md={12} lg={12}>
                        <div className='description'>
                            <p
                                dangerouslySetInnerHTML={{ __html: langSwitch ? details?.content : details?.content_ar }}
                            >
                            </p>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col md={6}>
                        <div className="form-group">
                            <input type="text" name="name" key={data} value={data?.name} className="form-control" placeholder={`${t('your first name')} *`} onChange={handleInputChange} />
                            <p className="error">{errors?.name}</p>
                        </div>
                    </Col>
                    <Col md={6}>
                        <div className="form-group">
                            <input type="text" name="last_name" key={data} value={data?.last_name} className="form-control" placeholder={`${t('your last name')} *`} onChange={handleInputChange} />
                            <p className="error">{errors?.last_name}</p>
                        </div>
                    </Col>
                    <Col md={6}>
                        <div className="form-group">
                            <input type="text" name="email" key={data} value={data?.email} className="form-control" placeholder={`${t('email')} *`} onChange={handleInputChange} />
                            <p className="error">{errors?.email}</p>
                        </div>
                    </Col>
                    <Col md={6}>
                        <div className="form-group mb-3">
                            <div className="d-flex gap-2">
                                <Select
                                    options={countryCode}
                                    value={countryCode.find(option => option.value === data?.country_code) || ""}
                                    className="country_code"
                                    onChange={handleCountryCode}
                                    placeholder={`${t("code")}*`}
                                    styles={{
                                        control: (provided) => ({
                                            ...provided,
                                            minHeight: "33px",
                                        }),
                                        valueContainer: (provided) => ({
                                            ...provided,
                                            height: "33px",
                                            padding: "0px 6px",
                                        }),
                                        indicatorsContainer: (provided) => ({
                                            ...provided,
                                            height: "33px",
                                        }),
                                    }}
                                />
                                <input
                                    type="number"
                                    name="phone"
                                    value={data?.phone || ""}
                                    ref={ref}
                                    className="form-control "
                                    placeholder={`${t("phone number")} *`}
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div className="d-flex gap-3">
                                {errors?.country_code && <p className="error">{errors.country_code}</p>}
                                {errors?.phone && <p className="error">{errors.phone}</p>}
                            </div>

                        </div>
                    </Col>
                    <Col md={12} className="position-relative">
                        <div className="form-group upload__cv" onClick={() => fileInputRef.current.click()}>
                            <input
                                name="image"
                                onChange={handleInputChange}
                                multiple={false}
                                ref={fileInputRef}
                                accept=".pdf, .jpeg, .jpg"
                                type="file"
                                hidden
                            />

                            <div>
                                <img width={30} src={'./assets/img/upload-cloud.svg'} />
                                <label className="mx-3 upload_cv_text" onClick={() => {
                                    const fileUrl = data?.image && URL.createObjectURL(data?.image);
                                    data?.image && window.open(fileUrl, '_blank');
                                }
                                }>{data?.image ? data?.image?.name : t("upload cv")}</label>
                            </div>
                            <p className="supporting_documents">{t("supporting documents (PDF, JPG, PNG )")}</p>
                        </div>
                        <p className="error position-absolute">{errors?.image}</p>
                    </Col>
                </Row>
                <Row className="mt-4 mb-4">
                    <Col md={8} sm={12} className="position-relative mb-2">
                        <div className="d-flex align-items-start">
                            <Form.Check
                                type={"checkbox"}
                                id={`consent-checkbox`}
                                className={`consent-checkbox`}
                                label={``}
                                name="agree"
                                onChange={handleInputChange}
                                checked={data?.agree || false}
                            />
                            <div className="d-flex flex-column align-items-start mx-2">
                                <label htmlFor="consent-checkbox" className="consent-label-1 consent-label">
                                    {t("I consent to be contacted by Emirates Human Rights Association (EHRA) about volunteering.")}
                                </label>
                                <label htmlFor="consent-checkbox" className="consent-label">
                                    {t("I confirm that the information provided is true and accurate.")}
                                </label>
                            </div>
                        </div>

                        <p className="error mb-2">{errors?.agree}</p>
                    </Col>
                    <Col md={6} className="form-group mb-2">
                        <div className={"captcha_alignnment"+(i18n?.language === "en"?' lang-en':' lang-ar')}>
                            <div id="captcha"></div>
                            <input
                                type="text"
                                placeholder={t("enter captcha")}
                                id="cpatchaTextBox"
                                className="form-control"
                                value={value}
                                onChange={(e) => {
                                    setValue(e.target.value);
                                    setErrors((prevErrors) => ({
                                        ...prevErrors,
                                        captcha: ""
                                    }));
                                }}
                                autoComplete="off"
                            />

                            <div className="text-right captcha_refresh">
                                <div className="refresh-capcha" onClick={() => {
                                    createCaptcha();
                                    setValue("");
                                }}>
                                    <img src="assets/img/icons/refresh.svg" alt="refresh captcha" />
                                </div>
                            </div>
                        </div>

                        <p className="error">{errors?.captcha}</p>
                    </Col>

                </Row>
                <Col md={12} sm={12} className="mb-3 d-flex align-items-end justify-content-end">
                    <button
                        disabled={loading}
                        className={`submit`}
                        onClick={handleSubmit}
                    >
                        {t('submit')}
                        {loading ? (
                            <Spinner className="mx-2" animation="border" size="sm" />
                        ) : (
                            <img 
                            className={`mx-2 ${i18n?.language !== "en" ? "arrow_image" : ""}`} 
                            src={icon} 
                            alt="Submit Icon" 
                            width={35} 
                        />
                        
                        )}
                    </button>

                </Col>
            </Container>
            <img src={`${API_IMAGE_URL}/enquiries/${details?.image}`} width={"100%"} />
        </section>
    );
}

export default VolunteerWithUs;

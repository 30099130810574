import React, { useEffect, useRef, useState } from "react";
import { Col, Container, Modal, Navbar, Row, Spinner, Form } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import logo from "../../assets/images/logo.svg";
import searchIcon from "../../assets/images/search_icon.svg";
import menuIcon from "../../assets/images/menu_icon.svg";
import menuIconWhite from "../../assets/images/logo_white.svg";
import closeIcon from "../../assets/images/close.svg";
import Xlogo from "../../assets/images/x.svg";
import fblogo from "../../assets/images/fb.svg";
import insta from "../../assets/images/insta.svg";
import youtubeLogo from "../../assets/images/youtube.svg";
import linkedinLogo from "../../assets/images/test11.svg";
import icon from "../../assets/images/right_arrow_yellow.svg";
import "./Header.scss";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import RoundedButton from "../../commonComponents/RoundedButton/RoundedButton";
import { useTranslation } from "react-i18next";
import { complaintsform_loading, contact_details, featuredNews, generalData, logo_ar, logo_en } from "../../redux/commonReducer";
import { useDispatch, useSelector } from "react-redux";
import { complaintsForm } from "../../redux/actionCreator";
import TitleBar from "../TitleBar/TitleBar";
import { toast } from "react-toastify";
import Select from "react-select";
import { countries } from "countries-list";
import { getCountries, getCountryCallingCode } from "libphonenumber-js";
import { Underline } from "lucide-react";




export const Header = () => {
  const [scroll, setScroll] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const [menuSearch, setSearchOpen] = useState(false);
  const [keyword, setKeyword] = useState('');
  const [captchaValue, setCaptchaValue] = useState("");
  const [value, setValue] = useState("")
  const [modalShow, setModalShow] = useState(false);
  const [lang, setLang] = useState('')
  const { t, i18n } = useTranslation();

  const navigate = useNavigate()
  const location = useLocation()
  // SELECTORS
  const general_data = useSelector(generalData)
  const logo_arabic = useSelector(logo_ar)
  const logo_english = useSelector(logo_en)
  const contactData = useSelector(contact_details)
  const featured_News = useSelector(featuredNews)
  const complaintsSubmitLoading = useSelector(complaintsform_loading)

  const countryOptions = Object.entries(countries).map(([code, { name }]) => ({
    value: name,
    label: name,

  }))
  const countryCode = getCountries().map((code) => ({
    value: `+${getCountryCallingCode(code)}`,
    label: `+${getCountryCallingCode(code)}`,
  }));


  // LANGUAGE
  const langSwitch = i18n?.language === 'en'
  const defaultLang = localStorage.getItem('localLang')

  const ref = useRef()
  const fileInputRef = useRef();
  const emiratesIdInputRef = useRef();
  const dispatch = useDispatch()

  const [errors, setErrors] = useState({});
  const [data, setData] = useState({});

  const handleInputChange = (e) => {
    const { name, type, value, files, checked } = e.target;

    setData((prevData) => ({
        ...prevData,
        [name]: (name === "image" || name === "emirates_id")
            ? (files?.length ? files[0] : prevData?.[name]) 
            : (type === "checkbox" ? checked : value.trim()), 
    }));

    setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: "",
    }));
};



  const handleCountryChange = (selectedOption) => {
    setErrors((prev) => ({
      ...prev,
      nationality: "",
    }));
    setData((prev) => ({ ...prev, nationality: selectedOption?.value || '' }));
  };

  const handleCountryCode = (selectedOption) => {
    setErrors((prev) => ({
      ...prev,
      country_code: "",
    }));

    setData((prev) => ({
      ...prev,
      country_code: selectedOption?.value || "",
    }));
  };


  const validateFileFormat = (file) => {
    const allowedFormats = /\.(jpg|jpeg|png|pdf)$/i;
    return file && allowedFormats.test(file.name);
  };

  const validation = async () => {
    let isValid = true;
    let errors = {};

    if (!data?.name?.trim()) {
      errors.name = t('first name is required');
      isValid = false;
    }
    if (!data?.last_name?.trim()) {
      errors.last_name = t('last name is required');
      isValid = false;
    }

    if (data?.image && !validateFileFormat(data.image)) {
      errors.image = t('Invalid file format. Only JPG, PNG, and PDF are supported');
      isValid = false;
    }


    if (!data?.subject?.trim()) {
      errors.subject = t('subject is required');
      isValid = false;
    }

    if (data.agree !== true) {
      errors.agree = t('You must agree to terms and conditions');
      isValid = false;
    }

    if (!data?.summary?.trim()) {
      errors.summary = t('summary is required');
      isValid = false;
    }
    if (!value || value.trim() === "") {
      errors.captcha = t("captcha is required");
      isValid = false
  } else if (value !== captchaValue) {
      errors.captcha = t("Invalid captcha");
      isValid = false
  }

    if (!data?.nationality?.trim()) {
      errors.nationality = t('nationality is required');
      isValid = false;
    }
    if (!data?.country_code?.trim()) {
      errors.country_code = t('code is required');
      isValid = false;
    }

    if (data?.emirates_id && !validateFileFormat(data.emirates_id)) {
      errors.emirates_id = t('Invalid format. Only JPG, PNG, and PDF are supported');
      isValid = false;
    }


    if (!data?.email?.trim()) {
      errors.email = t('Email is required');
      isValid = false;
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(data.email)) {
      errors.email = t('Invalid email address');
      isValid = false;
    }

    if (!data?.phone?.trim()) {
      errors.phone = t('Phone Number is required');
      isValid = false;
    } else if (!/^\+?\d{5,14}$/.test(data.phone)) {
      errors.phone = t('Invalid phone number format');
      isValid = false;
    }

    setErrors(errors);
    return isValid;
  };



  const handleSubmit = async () => {
    const isValidated = await validation()
    if (!isValidated) {
      return
    } else {
      let formData = new FormData();
      formData.append('name', data?.name);
      formData.append('last_name', data?.last_name);
      formData.append('image', data?.image);
      formData.append('type', 'complaint');
      formData.append("country_code", data?.country_code)
      formData.append('phone', data?.phone);
      formData.append('email', data?.email);
      formData.append('subject', data?.subject);
      formData.append('summary', data?.summary);
      formData.append('nationality', data?.nationality);
      formData.append('emirates_id', data?.emirates_id);

      dispatch(complaintsForm(formData, (res) => {
        toast.success(res.data.message, {
          position: "bottom-center",
          autoClose: 3000
        })
        createCaptcha();
        setData({
          name: '',
          last_name: "",
          email: '',
          phone: '',
          agree: '',
          image: '',
          subject: '',
          summary: '',
          nationality: '',
          emirates_id: ''
        });
        setValue("")
      }))
    }
  }



  const changeLanguage = lng => {
    i18n.changeLanguage(lng);
  };
  

  useEffect(() => {
    if (i18n?.language == 'ar' || defaultLang == 'en-US') {
      document.body.classList.add('rtl');
    } else {
      document.body.classList.remove('rtl');
    }
  }, [langSwitch])


  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 50);
    });
  }, []);


  function createCaptcha() {
    let code;
    document.getElementById("captchar").innerHTML ="";
    let charsArray =
      "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ@!#$%^&*";
    let lengthOtp = 6;
    let captcha = [];
    for (let i = 0; i < lengthOtp; i++) {
      var index = Math.floor(Math.random() * charsArray?.length + 1);
      if (captcha.indexOf(charsArray[index]) == -1)
        captcha.push(charsArray[index]);
      else i--;
    }
    let canv = document.createElement("canvas");
    canv.id = "captcha";
    canv.width = 100;
    canv.height = 35;
    let ctx = canv.getContext("2d");
    ctx.font = "25px Georgia";
    ctx.strokeText(captcha.join(""), 0, 30);

    code = captcha.join("");
    setTimeout(() => {
      document.getElementById("captchar")?.appendChild(canv);
      setCaptchaValue(code);
    }, 1000);

  }

  const handleMenu = (e) => {
    if (e == "search") {
      setSearchOpen(!menuSearch);
      setMenuOpen(false);
    } else if (e == "menu") {
      setMenuOpen(!menuOpen);
      setSearchOpen(false);
    }
  };

  const handleCloseMenu = () => {
    setSearchOpen(false);
    setMenuOpen(false);
  };

  const handleSearch = (e) => {
    navigate('/search', {
      state: {
        key: keyword
      }
    })
    setSearchOpen(false);
    setMenuOpen(false);
  }


  useEffect(() => {
    if (!keyword == '') {
      const keyDownHandler = event => {
        if (event.key === 'Enter') {
          event.preventDefault();
          handleSearch();
        }
      };
      document.addEventListener('keydown', keyDownHandler);
      return () => {
        document.removeEventListener('keydown', keyDownHandler);
      };
    }

  }, [keyword]);


  return (
    <>
      <Container>
        <Navbar className={(scroll ? "main__header scrolled" : "main__header nav-fixed") + (location?.pathname == '/' ? ' bg-white' : '')}>
          <Container>
            <Navbar.Brand>
              <Link to="/">
                {
                  !logo_english == '' && !logo_arabic == '' ? <img
                    alt=""
                    src={langSwitch ? logo_english : logo_arabic}
                    className="d-inline-block align-top logo-image"
                  /> : <img
                    alt=""
                    src={logo}
                    className="d-inline-block align-top logo-image"
                  />
                }

              </Link>
            </Navbar.Brand>
            <Navbar.Text className="d-flex align-center justify-center text-align-center">
              <ul className="language__switch">
                <li className="sm-none contact">
                  <NavLink to="/volunteer_with_us">{t("volunteer with us")}</NavLink>
                </li>
                <li
                  className="sm-none contact"
                  onClick={() => {
                    setModalShow(true);
                    setTimeout(() => {
                    createCaptcha();
                    }, 100);
                  }}
                >
                  <Link>{t("complaints")}</Link>
                </li>

                {/* <li className="sm-none contact">
                  <NavLink to="/enquiry">{t("enquiry")}</NavLink>
                </li> */}
                <li className="sm-none contact">
                  <NavLink to="/contact">{t("contact us")}</NavLink>
                </li>
                <li className={langSwitch && 'active'} onClick={() => changeLanguage("en")}>EN</li>
                <li className={!langSwitch && 'active'} onClick={() => changeLanguage("ar")}>AR</li>
              </ul>
              <img
                alt=""
                src={searchIcon}
                onClick={() => handleMenu("search")}
                height="18"
                className="mr-3 ml-3 align-self-center pointer icon__header"
              />
              <img
                alt=""
                src={menuIcon}
                height="18"
                className="align-self-center pointer icon__header"
                onClick={() => handleMenu("menu")}
              />
            </Navbar.Text>
          </Container>
        </Navbar>
      </Container>
      {(menuOpen || menuSearch) && (
        <div
          className={menuOpen ? "menu__items open" : "menu__items"}
          data-aos="fade-down"
        >
          <div className="header__logo">
            <div className="logo">
              <img src={menuIconWhite} alt="" />
            </div>
            <div
              className="closeMenu pointer"
              onClick={() => handleCloseMenu()}
            >
              <img src={closeIcon} alt="" />
              <h6>{t("close")}</h6>
            </div>
          </div>
          <div className="row">
            <div className="col-md-8">
              <div
                className="menu__links"
              >
                {menuSearch ? (
                  <div className="search__box">
                    <h2>{t("search here")}</h2>
                    <p>{langSwitch ? general_data?.search_title : general_data?.search_title_ar}</p>
                    <input type="text" placeholder={t('search here')} className="form-control" onChange={(e) => setKeyword(e.target.value)} />
                    <div className="text-right">
                      <RoundedButton noIcon title={t('search')} varient={'primary'} onClick={handleSearch} />
                    </div>
                  </div>
                ) : (
                  <>
                    <ul data-aos="fade-zoom-in" data-aos-duration="1000"
                      onClick={() => handleCloseMenu()}
                    >
                      <li>
                        <span></span>
                        <NavLink to="/">{t("home")}</NavLink>
                      </li>
                      <li>
                        <span></span>
                        <NavLink to="/about">{t("about us")}</NavLink>
                      </li>
                      <li>
                        <span></span>
                        <NavLink to="/what-we-do">{t("what we do")}</NavLink>
                      </li>
                      {
                        featured_News?.length > 0 &&
                        <li>
                          <span></span>
                          <NavLink to={"/News"}>{t("news")}</NavLink>
                        </li>
                      }

                      <li>
                        <span></span>
                        <NavLink to="/local-events">{t("local events")}</NavLink>
                      </li>
                      <li>
                        <span></span>
                        <NavLink to="/Events">{t("international events")}</NavLink>
                      </li>
                      <li>
                        <span></span>
                        <NavLink to="/blogs">{t("blogs")}</NavLink>
                      </li>

                    </ul>
                    <ul
                      data-aos="fade-zoom-in"
                      data-aos-duration="2000"
                      onClick={() => handleCloseMenu()}
                    >
                      <li>
                        <span></span>
                        <NavLink to="/Research">{t("research")}</NavLink>
                      </li>
                      <li>
                        <span></span>
                        <NavLink to={`/library/gallery`}>{t("gallery")}</NavLink>
                      </li>
                      <li>
                        <span></span>
                        <NavLink to={`/library/reports`}>{t("report")}</NavLink>
                      </li>
                      <li>
                        <span data-hide-zero={featured_News?.length > 0 ? "true" : "false"}></span>
                        <NavLink to={`/library/awareness`}>{t("awareness")}</NavLink>
                      </li>
                      <li>
                        <span data-hide-zero="true"></span>
                        <NavLink to="/Faq">{t("faq")}</NavLink>
                      </li>
                      <li>
                        <span data-hide-zero="true"></span>
                        <NavLink to="/Contact">{t("contact us")}</NavLink>
                      </li>
                    </ul>
                  </>
                )}
              </div>
            </div>
            <div className="col-md-4">
              <div className="menu__contact">
                <div
                  className="contact__details nav-fade"
                >
                  <h3>{t("address")}</h3>
                  <p>{langSwitch ? contactData?.address : contactData?.address_ar} </p>
                </div>
                <div
                  className="contact__details nav-fade"
                >
                  <h3>{t("email")}</h3>
                  <p><a href={`mailto:${contactData?.email}`}>{contactData?.email}</a></p>
                </div>
                <div
                  className="contact__details nav-fade"
                >
                  <h3>{t("phone")}</h3>
                  <p className="phoneRtl"><a href={`tel:${contactData?.telephone}`}>{contactData?.telephone}</a></p>
                </div>
                <div
                  className="contact__details nav-fade"
                >
                  {
                    (general_data?.facebook ||
                      general_data?.twitter ||
                      general_data?.youtube ||
                      general_data?.instagram ||
                      general_data?.linkedln) && <h3>{t("follow us")}</h3>
                  }

                  <ul>
                    {general_data?.facebook && <li> <a href={general_data?.facebook} target="_blank"> <img src={fblogo} alt="" /> </a> </li>}
                    {general_data?.twitter && <li> <a href={general_data?.twitter} target="_blank"> <img src={Xlogo} alt="" /> </a> </li>}
                    {general_data?.youtube && <li> <a href={general_data?.youtube} target="_blank"> <img src={youtubeLogo} alt="" /> </a> </li>}
                    {general_data?.instagram && <li> <a href={general_data?.instagram} target="_blank"> <img src={insta} alt="" /> </a> </li>}
                    {general_data?.linkedln && <li> <a href={general_data?.linkedln} target="_blank"> <img src={linkedinLogo} alt="" /> </a> </li>}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* MODAL */}
      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={modalShow}
        onHide={() => { setModalShow(false) }}
      >
        <Modal.Body>
          <div className="member__modal complaints__modal">
            <div
              className="modal-close"
              onClick={() => {
                setModalShow(false);
                setData({});
                setErrors({})

              }}
            >
              <img src="/assets/img/icons/modal-close.svg" alt="" />
            </div>
            <TitleBar
              title={t('complaint registration')}
              noBtn
              onClose={() => {
                navigate("/")
                setModalShow(false);
              }}
              setData={setData}
              setError={setErrors}
              breadcrumbs={[
                { name: t("home"), path: "/home" },
                { name: t("complaint registration"), path: "" }
              ]}
            />
            <Row>
              <Col md={12}>
                <div className="form-group mb-3 ">
                  <input type="text" name="subject" value={data?.subject} ref={ref} className="form-control" placeholder={`${t('subject')} *`}
                    onChange={handleInputChange} />
                  {errors?.subject && (
                    <p className="error">{errors?.subject}</p>
                  )}

                </div>
              </Col>
              <Col md={6}>
                <div className="form-group mb-3">
                  <input type="text" name="name" value={data?.name} ref={ref} className="form-control" placeholder={`${t('your first name')} *`} onChange={handleInputChange} />
                  {errors?.name && (
                    <p className="error">{errors?.name}</p>
                  )}
                </div>
              </Col>
              <Col md={6}>
                <div className="form-group mb-3" >
                  <input type="text" name="last_name" value={data?.last_name} ref={ref} className="form-control" placeholder={`${t('your last name')} *`} onChange={handleInputChange} />
                  {errors?.last_name && (
                    <p className="error">{errors?.last_name}</p>
                  )}

                </div>
              </Col>
              <Col md={6}>
                <div className="form-group">
                  <input type="text" name="email" value={data?.email} ref={ref} className="form-control" placeholder={`${t('email')} *`} onChange={handleInputChange} />
                  {errors?.email && (
                    <p className="error">{errors?.email}</p>
                  )}

                </div>
              </Col>

              <Col md={6}>
                <div className="form-group mb-3">
                  <div className="d-flex gap-2">
                    <Select
                      options={countryCode}
                      value={countryCode.find(option => option.value === data?.country_code) || ""}
                      className="country_code"
                      onChange={handleCountryCode
                      }
                      placeholder={`${t("code")}*`}
                      styles={{
                        control: (provided) => ({
                          ...provided,
                          minHeight: "33px",
                        }),
                        valueContainer: (provided) => ({
                          ...provided,
                          height: "33px",
                          padding: "0px 6px",
                        }),
                        indicatorsContainer: (provided) => ({
                          ...provided,
                          height: "33px",
                        }),
                      }}
                    />
                    <input
                      type="number"
                      name="phone"
                      value={data?.phone || ""}
                      ref={ref}
                      className="form-control "
                      placeholder={`${t("phone number")} *`}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="d-flex gap-3">
                    {errors?.country_code && <p className="error">{errors.country_code}</p>}
                    {errors?.phone && <p className="error">{errors.phone}</p>}
                  </div>

                </div>
              </Col>

              <Col md={6}>
                <div className="form-group ">
                  <Select
                    options={countryOptions}
                    value={countryOptions.find(option => option.value === data?.nationality) || ""}
                    onChange={handleCountryChange}
                    className="country_text"
                    placeholder={`${t("nationality")} *`}
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        minHeight: "33px",
                      }),
                      valueContainer: (provided) => ({
                        ...provided,
                        height: "33px",
                        padding: "0px 6px",
                      }),
                      indicatorsContainer: (provided) => ({
                        ...provided,
                        height: "33px",
                      }),
                    }}
                  />
                  {errors?.nationality && (
                    <p className="error">{errors?.nationality}</p>
                  )}

                </div>
              </Col>
              <Col md={6}>
                <div className="form-group">
                  <input
                    name="emirates_id"
                    onChange={handleInputChange}
                    ref={emiratesIdInputRef}
                    accept=".jpg, .png, .pdf"
                    type="file"
                    hidden
                  />

                  <div className="form-control custom__upload cursor-pointer" onClick={() => {
                    if (data?.emirates_id) {
                      const fileUrl = data?.emirates_id && URL.createObjectURL(data?.emirates_id);
                      data?.emirates_id && window.open(fileUrl, '_blank');

                    }
                  }}>
                    <label className="emirates_id_label" for="files">{data?.emirates_id ? data?.emirates_id?.name : `${t("emirates id")} `}</label>
                    <div className="upload__text" onClick={() => {
                      emiratesIdInputRef?.current?.click()
                    }} >
                      {t("upload")}
                    </div>
                  </div>
                  <p className="supporting_documents">{t("supporting documents (PDF, JPG, PNG )")}</p>
                  <p className="error">{errors?.emirates_id}</p>
                </div>
              </Col>
              <Col md={12}>
                <div className="form-group mb-0">
                  <textarea style={{ height: 80 }} type="text" name="summary" value={data?.summary} ref={ref} className="form-control" placeholder={`${t('summary')} *`} onChange={handleInputChange} />
                  <p className="error">{errors?.summary}</p>
                </div>
              </Col>
              <Col md={12} className="position-relative">
                <div className="form-group upload__cv" onClick={() => fileInputRef.current.click()}>
                  <input name="image" onChange={handleInputChange} multiple={false} ref={fileInputRef} accept=".jpg, .png, .pdf" type='file' hidden />
                  <div>
                    <img width={30} src={'./assets/img/upload-cloud.svg'} alt="nothing" />
                    <label className="mx-3 upload_cv_text" onClick={() => {
                      const fileUrl = data?.image && URL.createObjectURL(data?.image);
                      data?.image && window.open(fileUrl, '_blank');
                    }}>{data?.image ? data?.image?.name : t("Upload Attachment")}</label>
                  </div>
                  <p className="supporting_documents">{t("supporting documents (PDF, JPG, PNG )")}</p>
                </div>
                <p className="error position-absolute">{errors?.image}</p>
              </Col>
            </Row>


            <Row className="mt-3 mb-3">
              <Col md={12} className="position-relative mb-3">
                <div className="d-flex align-items-start">
                  <Form.Check
                    type="checkbox"
                    id="consent-checkbox"
                    className="consent-checkbox"
                    label=""
                    name="agree"
                    onChange={handleInputChange}
                    checked={data?.agree || false}
                  />
                  <div className="d-flex flex-column align-items-start ms-2">
                    <label htmlFor="consent-checkbox" className="consent-label-1 consent-label">
                      {t("I agree to be contacted by Emirates Human Rights Association (EHRA) regarding this complaint. I declare that all information provided in this form is true and accurate.")}
                    </label>

                  </div>
                </div>
                {errors?.agree && (
                  <p className="error">{errors?.agree}</p>
                )}
              </Col>
              <Col md={8} className="form-group mb-2">
              <div className={"captcha_alignnment"+(i18n?.language === "en"?' lang-en':' lang-ar')}>
                  <div id="captchar"></div>
                  <input
                    type="text"
                    placeholder={t("enter captcha")}
                    id="cpatchaTextBox"
                    className="form-control"
                    value={value}
                    onChange={(e) => {
                      setValue(e.target.value);
                      setErrors((prevErrors) => ({
                        ...prevErrors,
                        captcha: ""
                      }));
                    }}
                    autoComplete="off"
                  />

                  <div className="text-right captcha_refresh">
                    <div className="refresh-capcha" onClick={() => {
                      createCaptcha();
                      setValue("");
                    }}>
                      <img src="assets/img/icons/refresh.svg" alt="refresh captcha" />
                    </div>
                  </div>
                </div>

                <p className="error">{errors?.captcha}</p>
              </Col>
              <Col md={12} className="d-flex align-items-center justify-content-end mt-3">
                <button disabled={complaintsSubmitLoading} className="submit" onClick={handleSubmit}>
                  {t('report now')}
                  {!complaintsSubmitLoading ? <img src={icon} alt="" width={35} className={`mx-2 ${i18n?.language !== "en" ? "arrow_image" : ""}`}  /> :
                    <Spinner className="mx-2" animation="border" width={20} size="sm" />}
                </button>
              </Col>

            </Row>


          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

import React, { useState } from "react";
import SlidesComponent from "../../commonComponents/SlidesComponent/SlidesComponent";
import "../pagesStyles.scss";
import LatestVideos from "../../components/Library/LatestVideos";
import { Tab, Tabs } from "react-bootstrap";
import Title from "../../commonComponents/Title/Title";
import { featuredVideosItems, galleryItems, gallery_count, videosItems } from "../../redux/commonReducer";
import { useDispatch, useSelector } from "react-redux";
import i18n from "../../i18n";
import { useEffect } from "react";
import { getFeaturedVideos, getGallery, getVideos } from "../../redux/actionCreator";
import { API_IMAGE_URL } from "../../constants/configuration";
import moment from "moment";
import { SwiperSlide } from "swiper/react";
import { t } from "i18next";
import { Link } from "react-router-dom";
import RoundedButton from "../../commonComponents/RoundedButton/RoundedButton";
import Fancybox from "../../components/LightBox/fancybox";


function Libraries() {
  const [key, setKey] = useState("");
  const dispatch = useDispatch();

  const [page, setPage] = useState(0);
  const [index, setIndex] = useState(0)
  const [allImages, setAllImages] = useState([])

  // SELECTOR
  const gallery_lists = useSelector(galleryItems);
  const Remaining_count = useSelector(gallery_count);
  const featuredVideos = useSelector(featuredVideosItems);
  const allVideos = useSelector(videosItems);
  const videos_lists = featuredVideos?.concat(allVideos)

  // LANGUAGE
  const [lang, setLang] = useState("");
  const langSwitch = i18n?.language === "en";
  useEffect(() => {
    if(gallery_lists?.length==0 && videos_lists?.length!=0){
      setKey('gallery')
    }else{
      setKey('latestVideos')
    }
    // 
  }, [allVideos,gallery_lists])
  

  useEffect(() => {
    if(gallery_lists?.length==0 && videos_lists?.length!=0){
      setKey('gallery')
    }else{
      setKey('latestVideos')
    }
    // if(key==''){
    //   setKey('gallery')
    // }else if(key != 'gallery'){
    //   setKey('latestVideos')
    // }else if(key != 'latestVideos'){
    //   setKey('gallery')
    // }

    i18n.on("languageChanged", (language) => {
      setLang(language);
    });
  }, [lang, i18n]);

  useEffect(() => {
    const formData = new FormData();
    formData.append("page", page);
    formData.append("perPage", 16);
    dispatch(getGallery(formData, (res) => {
      setAllImages([...allImages, ...res])
    }));

    dispatch(getVideos())
    dispatch(getFeaturedVideos())
  }, [page]);

  const handleViewMore = () => {
    setPage(page + 1);
  };


  
  const libraries = (index, ele) => (
    <>
      <SwiperSlide>
        <Fancybox>
          
          <a data-fancybox={`gallery${index}`} href={`${API_IMAGE_URL}${ele?.file_path}${ele.thumbnail}`}>
            <div
              className="library__grid"
              style={{
                backgroundImage: `url('${API_IMAGE_URL}${ele?.file_path}${ele?.thumbnail}')`,
              }}
              key={index} onClick={() => handleImage(ele, index)}
            >
              <div className="overlay"></div>
              <div className="library__contents">
                <h3>{moment.utc(ele?.created_date).locale(lang).format("LL")} </h3>
                <h6>{langSwitch ? ele?.title : ele?.title_ar}</h6>
              </div>
            </div>
          </a>
        </Fancybox>
      </SwiperSlide>
    </>
  );

  const handleImage = (ele, index) => {
    setIndex(index)
  }
  return (
    

    <section className="mb-5 height-100 library">
      {
        gallery_lists?.length > 0 || videos_lists?.length > 0 ? <Title title={t("library")} titleurl={'/library/gallery'}  /> : ''
      }

      <Tabs
        id="controlled-tab-example"
        activeKey={key}
        onSelect={(k) => setKey(k)}
        className="mt-5"
      >
             {
          videos_lists?.length > 0 ?
            <Tab eventKey='latestVideos' title={t("latest videos")}>
              <LatestVideos classname={"mt-4"} />
            </Tab> : ''
        }
        {
          gallery_lists?.length > 0 ?
            <Tab eventKey="gallery" title={t("gallery")}>

              <SlidesComponent
                grid={{
                  rows: 2,
                  fill: "row",
                }}
                breakpoints={{
                  640: {
                    slidesPerView: 2,
                    spaceBetween: 25,
                  },
                  768: {
                    slidesPerView: 3,
                    spaceBetween: 25,
                  },
                  1024: {
                    slidesPerView: 4,
                    spaceBetween: 25,
                  },
                }}
                dataArray={allImages}
                tile={libraries}
              />
              <>
                <div className="d-none">
                  {allImages?.[index]?.gallery?.map((ele, i) => {
                    return (
                      <a data-fancybox={`gallery${index}`} href={`${API_IMAGE_URL}/library/${ele}`} key={i}>
                        <img src={`${API_IMAGE_URL + '/library/' + ele}`} width="100" />
                      </a>
                    );
                  })}
                </div>
              </>
              {/* <div className="col-md-12">
            {!Remaining_count == 0 && (
              <div className="text-center mb-5 mt-4">
                <RoundedButton
                  varient="secondary"
                  style={{ minWidth: "190px" }}
                  onClick={() => handleViewMore()}
                  title="View More"
                />
              </div>
            )}
          </div> */}
            </Tab> : ''}
   

      </Tabs>
    </section>
  );
}

export default Libraries;

import { ArrowRight } from 'lucide-react'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import './Confirmation.scss'
import { t } from "i18next";
import { useTranslation } from 'react-i18next';

const Confirmation = ({ onClose, type }) => {
  const navigate = useNavigate();

  const { t, i18n } = useTranslation();

  const handleClick = () => {
    onClose();
    navigate("/");
  };

  let message = t("We will send you a payment link to the registered email address and phone number shortly!");

  if (type?.length === 1) {
    if (type.includes("email")) {
      message = t("We will send you a payment link to the registered email address.");
    } else if (type.includes("phone")) {
      message = t("We will send you a payment link to the registered phone number.");
    }
  }

  return (
    <div className='confirmation_section'>
      <img src="/assets/img/tick-circle-svgrepo-com.svg" alt="response" />
      <h4>{t("thank you")}</h4>
      <p>{message}</p>

      <div className="back_home">
        <button onClick={handleClick}>
          {t("back to home")}
          <img src="/assets/img/Group 164.svg" alt="good" className={`mx-2 ${i18n?.language !== "en" ? "arrow_image" : ""}`}    />
        </button>
      </div>
    </div>
  );
};

export default Confirmation;

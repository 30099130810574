import { useNavigate, useParams } from "react-router-dom";
import { getBlogDetails, getBlogs } from "../../redux/actionCreator";
import RoundedButton from "../../commonComponents/RoundedButton/RoundedButton";
import {
  blog_data,
  blog_details,
  blog_page_details,
} from "../../redux/commonReducer";
import i18n from "../../i18n";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { Col, Container, Row } from "react-bootstrap";
import { API_IMAGE_URL } from "../../constants/configuration";
import { t } from "i18next";
import TitleBar from "../../components/TitleBar/TitleBar";
import { useEffect, useState } from "react";

const BlogDetails = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const langSwitch = i18n?.language === "en";

  const blogData = useSelector(blog_data);
  const pageDetails = useSelector(blog_page_details);
  const blogDetails = useSelector(blog_details);

  const [data, setData] = useState({
    page: 0,
    perPage: 5,
  });
  const [lang, setLang] = useState("");

  useEffect(() => {
    if (id) {
      dispatch(getBlogDetails(id));
    }
    getSearchDataHandler(data);
  }, [id]);

  const getSearchDataHandler = (filter) => {
    const formData = new FormData();
    formData.append("page", filter.page);
    formData.append("perPage", filter.perPage);
    formData.append("home", false);
    dispatch(getBlogs(formData));
  };

  const handlePageChange = () => {
    let temp;
    temp = {
      page: data?.page,
      perPage: data?.perPage + 4,
    };
    setData({
      ...temp,
    });
    getSearchDataHandler(temp);
  };

  return (
    <section className="blogs">
      <Container>
        <TitleBar
          breadcrumbs={[
            { name: `${t("home")}`, path: "/" },
            { name: `${t("blogs")}`, path: "/blogs" },
            { name: `${t("blog details")}`, path: "" },
          ]}
          noBtn
        />
        <div className="w-100">
          <Row>
            <Col md={12}>
              <h3 className="mb-4 blog_details_title">
                {langSwitch ? blogDetails?.title : blogDetails?.title_ar}
              </h3>
              <img
                className="blog_image"
                width={"100%"}
                src={`${API_IMAGE_URL}${blogDetails?.file_path}${blogDetails?.image}`}
              />
              <div className="blog__details mt-3">
                <Row>
                  <Col md={3} className="d-flex align-items-center justify-content-start right__border">
                    <img src="/assets/img/event.svg" width={20} className="mx-2" />
                   
                      {moment(blogDetails?.published_date).format("MMMM D, YYYY")}
                  </Col>
                  
                  <Col md={3} className="d-flex align-items-center justify-content-start">
                    <img src="/assets/img/clock.svg" width={20} className="mx-2" />
                   
                      {moment(blogDetails?.published_date).format("h:mm A")}
                  </Col>
                </Row>
              </div>
              <p
                className="py-4"
                dangerouslySetInnerHTML={{
                  __html: langSwitch
                    ? blogDetails?.content : blogDetails?.content_ar
                }}
              ></p>
            </Col>
          </Row>
          {blogData?.filter((item) => item?._id != id)?.length ? <h2 className="mt-3 mb-3">{t("read more")}</h2> : ""}
          <Row>
            {blogData
              ?.filter((item) => item?._id != id)
              ?.map((ele, i) => {
                return (
                  <Col
                    md={3}
                    sm={12}
                    onClick={() => navigate(`/blogs/blog-details/${ele?._id}`)}
                    style={{"cursor":"pointer"}}
                  >
                    <img
                      className="blog_detail_image"
                      width={"100%"}
                      src={`${API_IMAGE_URL}${ele?.file_path}${ele?.image}`}
                    />
                    <div className="blog__details mt-2">
                      <Row>
                        <Col md={7} className="d-flex align-items-center justify-content-start right__border read__more__blogs">
                          <img src="/assets/img/event.svg" width={15} className="mx-2" />
                          {moment(ele?.published_date).format("MMMM D, YYYY")}
                        </Col>
                        {console.log(blogDetails,ele.title, ele)}
                        <Col md={5} className="d-flex align-items-center justify-content-start read__more__blogs">
                          <img src="/assets/img/clock.svg" width={15} className="mx-2" />
                          {moment(ele?.published_date).format("hh:mm A")} 
                        </Col>
                      </Row>
                    </div>
                    <h5 className="mt-2">
                      {
                        langSwitch
                          ? ele?.title?.length > 60
                            ? ele?.title.slice(0, 60) + "......"
                            : ele?.title ?? ""
                          : ele?.title_ar?.length > 60
                            ? ele?.title_ar.slice(0, 60) + "......"
                            : ele?.title_ar ?? ""
                      }

                    </h5>
                    <p
                      className="mt-2"
                      dangerouslySetInnerHTML={{
                        __html: langSwitch
                          ? ele?.content?.length > 200
                            ? ele?.content?.slice(0, 200) + "(...)"
                            : ele?.content
                          : ele?.content_ar?.length > 200
                            ? ele?.content_ar?.slice(0, 200) + "(...)"
                            : ele?.content_ar,
                      }}
                    ></p>
                  </Col>
                );
              })}
          </Row>
        </div>
        {pageDetails?.pages == 1 ? (
          <></>
        ) : (
          <div className="text-center mb-5 mt-4">
            <RoundedButton
              varient="secondary"
              style={{ minWidth: "190px" }}
              onClick={() => handlePageChange()}
              title={t("View more")}
            />
          </div>
        )}
      </Container>
    </section>
  );
};

export default BlogDetails;
